import _ from 'lodash';
import { logger, Logger } from '@ssp/logger';
import { ContextMissing, ContextAssertion } from './errors';
import {
  Scope, ScopeData, CoreScopeData, isValidScope, Tags, ScopeFunc,
  Wrapper,
} from './types';
import { WRAPPERS } from './wrappers';

import type { ContextExecScope } from '@ssp/ts';

export interface BaseExecScope extends ContextExecScope {
  /** Logger Tags. */
  tags?: Tags;
}

export abstract class BaseExecScope extends Map {

  static isValidScope = isValidScope;
  isValidScope = isValidScope;

  constructor( data: ScopeData = {} ) {
    super();
    if ( ! _.isPlainObject( data ) ) {
      throw new TypeError(
        `Invalid argument to ExecScope constructor: "${data}"`,
      );
    }
    if ( data.scope && ! this.isValidScope( data.scope ) ) {
      throw new ContextAssertion( `Invalid scope "${data.scope}"`, {
        tags : { method : 'BaseExecScope#constructor' },
        data : { scope : data.scope },
      } );
    }
    this.set( data );
  }

  get user() {
    this.assert( 'user' );
    return this.demand( 'user' );
  }
  get log(): Logger { return this.get( 'logger', logger ); }
  get logger(): Logger { return this.get( 'logger', logger ); }

  get debugging() { return this.get( 'debugging', false ); }
  set debugging( dbg ) { this.set( 'debugging', Boolean( dbg ) ); }

  /** The scope this context is running in. */
  get scope(): Scope { return this.get( 'scope', 'anonymous' ); }

  /** The name of this context. */
  get name(): string { return this.get( 'name' ); }

  /** The debug label of this context. */
  get label(): string { return this.get( 'label' ); }

  has( key ): boolean { return ! _.isNil( this.get( key ) ); }

  /**
   * Get a value from the context, optionally providing a default
   * value in case it does not exist in the context.
   *
   * @param {string} key - The key to get (can be dotted for a deep key).
   * @param {any} [defaultValue=undefined] - The value to return if
   * the context does not contain a value for the given key.
   */
  get<K extends keyof CoreScopeData>( key: K ): CoreScopeData[K];
  get<K extends keyof CoreScopeData>(
    key: K, defaultValue: CoreScopeData[K],
  ): CoreScopeData[K];
  get<T = unknown, K extends string = string>( key: K, defaultValue: T ): T;
  get<T = unknown, K extends string = string>( key: K ): T;
  get<T = unknown, K extends string = string>( key: K, defaultValue?: T ): T {
    const parts = key.split( '.' );
    let val = super.get( parts.shift() );
    if ( parts.length ) val = _.get( val, parts );
    if ( _.isNil( val ) ) return defaultValue;
    return val;
  }

  /**
   * Set a value in the context.
   *
   * @param {string} key - The key to set.
   * @param {any} value - The value to set.
   */
  set<K extends keyof CoreScopeData>(
    key: K, value: CoreScopeData[K]
  ): CoreScopeData[K];
  set<T=unknown>( key: string, value: T ): T;
  set<T extends ScopeData>( data: T ): T;
  set( key: string|ScopeData, value?: any ) {
    if ( _.isPlainObject( key ) && ! value ) {
      for ( const [ k, v ] of Object.entries( key ) ) {
        this.set( k, v );
      }
      return key;
    } else if ( _.isString( key ) ) {
      if ( key === 'debug' ) key = 'debugging';
      if ( key.includes( '.' ) ) {
        throw new TypeError( `Cannot set deep property ${key}` );
      }

      this.debug( `Setting "${key}" to "${value}"` );
      super.set( key, value );

      if ( key === 'tags' ) this.rebuildLogger();
      return value;
    }
  }

  /**
   * Get a value from the context, throwing an exception if it does
   * not exist.
   *
   * @param key - The key to get (can be dotted for a deep key).
   * @param msg - Message to throw in the exception.
   */
  demand( key: string, msg?: string ) {
    const val = this.get( key );
    if ( _.isNil( val ) ) {
      throw new ContextMissing( {
        message : msg || `Context does not contain "${key}"`,
        tags    : { method : 'BaseExecScope#demand' },
        data    : { key },
      } );
    }
    return val;
  }

  addTags( tags: Tags ) {
    if ( ! _.isPlainObject( tags ) ) return;
    this.set( 'tags', { ...this.get( 'tags', {} ), ...tags } );
  }

  /**
   * Check whether the active context scope is a specific name.
   *
   * Scope can be one of `system`, `user`, or `anonymous`.
   *
   * @param scopes - The scopes to check.
   * @returns True if the active scope is any of the names
   * specified.
   */
  is( ...scopes: Scope[] ): boolean {
    const curr = this.scope;
    return _.some( _.flattenDeep( scopes ), scope => scope === curr );
  }

  /**
   * Create a child context.
   *
   * @param data - The context data.
   */
  child( data: ScopeData = {} ) {
    const Ctor = this.constructor as any;
    const newdata = this.extend( data );
    return new Ctor( newdata );
  }

  extend( data: ScopeData = {} ): ScopeData {
    if ( data.inherit === false ) return data;
    const res: ScopeData = {
      ..._.omit( this.toObject(), 'name', 'label' ),
      ...data,
      tags  : { ...this.get( 'tags', {} ), ...( data.tags || {} ) },
    };
    if ( res.user ) _.defaults( res.tags, { user : String( res.user ) } );
    return res;
  }

  /**
   * Run the provided function only if the active context scope is
   * `scope`.  If the active context scope is not `scope` then `func`
   * won't be run and this function will return false.
   *
   * @param scope - The scope to check for.
   * @param func - The function to run.
   * @returns The return value from the function, or `false` if it
   * wasn't run.
   */
  only<T=unknown>( scope: Scope, func: () => T ): T|false {
    if ( ! this.is( scope ) ) return false;
    return func();
  }

  /**
   * Run the provided function unless the active context scope is
   * `scope`.  If the active context scope is `scope` then `func`
   * won't be run and this function will return false.
   *
   * You can pass an array of scopes instead of a single scope if you
   * need to.
   *
   * @param scope - The scope to check for.
   * @param func - The function to run.
   * @returns  The return value from the function, or `false` if it
   * wasn't run.
   */
  unless<T=unknown>( scope: Scope, func: () => T ): T|false {
    if ( this.is( scope ) ) return false;
    return func();
  }

  /**
   * Throw an exception if the current scope does not match the provided
   * scope.
   *
   * @param scope - The scope to check for.
   * @param error - Override the error message in the thrown exception.
   * @returns Nothing useful.
   * @throws {ContextAssertion} Throws if running in the wrong context.
   */
  assert( scope: Scope, error?: string ): void {
    if ( this.is( scope ) ) return;
    const message = error
      || `Expected context scope to be "${scope}", but found "${this.scope}"`;
    throw new ContextAssertion( message, {
      tags : { method : 'BaseExecScope#assert' },
      data : { expected : scope, actual : this.scope },
    } );
  }

  abstract runInContext<R=unknown, A extends any[]=any[]>(
    func: ScopeFunc<R, A>,
    ...args: A
  ): Promise<R>;

  /** Create a child context and run a function in it. */
  async run<
    R = unknown,
    A extends any[] = any[],
    D extends ScopeData = ScopeData,
  >(
    data: D,
    func: ScopeFunc<R, A>,
    ...args: A
  ): Promise<R> {
    if ( ! ( _.isPlainObject( data ) && _.isFunction( func ) ) ) {
      throw new TypeError( `Invalid arguments to run: '${data}', '${func}'` );
    }
    const { wrappers, hooks = {}, ...opts } = data;
    if ( _.isFunction( hooks.before ) ) await hooks.before( opts, args );
    const child = this.child( opts );
    const wrapped = child.wrapContextFunction( func, wrappers );
    let res: any, err: Error;
    try {
      res = await child.runInContext( wrapped, ...args ); // @hide
    } catch ( error ) {
      err = error;
    }
    if ( err ) {
      if ( _.isFunction( hooks.error ) ) return hooks.error( err );
      throw err;
    } else {
      if ( _.isFunction( hooks.after ) ) return hooks.after( res );
      return res;
    }
  }

  toObject(): ScopeData { return Object.fromEntries( this.entries() ); }

  rebuildLogger(): void {
    const opts = this.get( 'logger_options', {} );
    // _.defaults( opts, { level : 'debug' } );
    const tags = _.omitBy( _.assign( {}, {
      ...this.get( 'tags', {} ),
      context_name    : this.name,
      context_scope   : this.scope,
      context_label   : this.label,
    } ), _.isNil );
    const newlog = logger.child( tags, opts );
    this.set( 'logger', newlog );
  }

  toString() {
    return _.compact( [
      this.name,
      this.label,
      `<scope: ${this.scope}>`,
    ] ).join( ' ' );
  }

  /*
  [ Symbol.for( 'nodejs.util.inspect.custom' ) ]( _depth, options ) {
    return options.stylize( `ExecScope<${this}>`, 'special' );
  }
  */

  /**
   * Wrap a function (and optional arguments) with some number of
   * wrapping functions.  Each wrapper will get called with two
   * arguments, this context object and a `next` callback that it can
   * call to invoke the next inner function.
   *
   * @param {Function} func - The function to execute.
   */
  wrapContextFunction( func, addl_wrappers: Wrapper[] = [] ) {
    let args = [];
    // eslint-disable-next-line prefer-spread
    let wrapped = () => func.apply( null, args );
    const wrappers = this.sortWrappers( [ ...addl_wrappers, ...WRAPPERS ] );
    /* eslint-disable no-loop-func */
    for ( const wrapper of wrappers ) {
      const next = wrapped;
      const arity = wrapper.length;
      if ( wrapper.length === 1 ) {
        // @ts-ignore
        wrapped = () => wrapper( next );
      } else if ( wrapper.length === 2 ) {
        // @ts-ignore
        wrapped = () => wrapper( this, next );
      } else if ( wrapper.length === 3 ) {
        // @ts-ignore
        wrapped = () => wrapper( this, args, next );
      } else {
        throw new Error( `Invalid context wrapper with arity of ${arity}` );
      }
    }
    /* eslint-enable no-loop-func */
    return ( ...args_in ) => {
      args = [ ...args_in ];
      return wrapped();
    };
  }

  sortWrappers( wrappers: Wrapper[] ) {
    return wrappers.sort( ( a, b ) => {
      return ( b.order || Infinity ) - ( a.order || Infinity );
    } );
  }

  abstract debug( ...args: any[] ): void;
}

declare global {
  // eslint-disable-next-line no-var, vars-on-top
  var ctx: BaseExecScope;
}
