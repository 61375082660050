import { logger } from '@ssp/logger';
import { ClientExecScope } from './ClientExecScope';

export const rootScope = new ClientExecScope( { logger } );
ClientExecScope.root = rootScope;

export * from './BaseExecScope';
export * from './ClientExecScope';
export * from './types';

export function getCurrentScope(): ClientExecScope { return rootScope; }

if ( typeof global !== 'undefined' ) {
  Object.defineProperty( global, 'ctx', {
    enumerable    : true,
    configurable  : true,
    get() { return getCurrentScope(); },
  } );
  Object.defineProperty( global, 'log', {
    enumerable    : true,
    configurable  : true,
    get() { return getCurrentScope().log; },
  } );
}
if ( typeof window !== 'undefined' ) {
  Object.defineProperty( window, 'ctx', {
    enumerable    : true,
    configurable  : true,
    get() { return getCurrentScope(); },
  } );
  Object.defineProperty( window, 'log', {
    enumerable    : true,
    configurable  : true,
    get() { return getCurrentScope().log; },
  } );
}
