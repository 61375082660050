import type { BaseExecScope } from './BaseExecScope';
import type { ContextExecOptions } from '@ssp/ts';

export type WrapperNextFunc = () => void;

export type WrapperFunc =
  | ( ( next: WrapperNextFunc ) => void )
  | ( ( context: BaseExecScope, next: WrapperNextFunc ) => void )
  | ( ( context: BaseExecScope, args: any, next: WrapperNextFunc ) => void );

export type Wrapper = WrapperFunc & { order?: number; };

export type Tag = string | boolean | number;
export type Tags = Record<string, Tag>;

export type ScopeFunc<R=unknown, A extends any[] = any[]> = ( ...args: A ) => R;

export interface ScopeHooks {
  /**
   * A hook that runs before the context is built.  It is called with
   * the data object passed to `ctx.run` (excluding the `hooks` and
   * `wrappers` properties) and the arguments as an array.  It can
   * modify these in-place if needed.
   */
  before?: ( options: any, args: any[] ) => void | Promise<void>;
  /**
   * A hook called after the context execution is successfully run.
   * It gets called with the return value of the context function, and
   * can return something else in order to change the return value.
   * If not changing the return value, then the after hook must return
   * the result that it was given.
   */
  after?: ( result: any ) => any | Promise<any>;
  /**
   * A hook called after the context execution has failed to run.  It
   * gets called with the error that was caught.  If this hook returns
   * a value then that will become the return value of the context
   * execution.  If you want the execution to still be a failure then
   * this hook must throw a new Error or rethrow the one it was given.
   */
  error?: ( error: Error ) => any | Promise<any>;
}

export interface CoreScopeData extends ContextExecOptions {
  /** A short name for the context. */
  name?: string;
  /** The context scope to run in (`system` or `user`). */
  scope?: Scope;
  /**
   * A label for the context layer, just makes it easier to see what
   * is going on in the logs.
   */
  label?: string;
  /** Enable detailed debugging logs for this context. */
  debugging?: boolean;
  /**
   * Tags to pass to the logger.  Each new context execution gets it's
   * own child logger, so tags provided here will be included in the
   * logs for all log messages produced in this context.
   */
  tags?: Tags;
  /**
   * If set to `false` then this context will not inherit any
   * properties from the context in which it was called.  Use with
   * care, this should only be done for contexts that are intended to
   * be top-level execution contexts, and you will need to manually include
   * any properties that should be included from their surrounding
   * context (especially things like application-level tags).
   */
  inherit?: boolean;
  /**
   * Wrapper functions that will be run like middleware around the
   * execution of the context function.
   */
  wrappers?: Wrapper[];
  hooks?: ScopeHooks;
}
export interface ScopeData extends CoreScopeData {
  [key: string]: any;
}

export const Scopes = [ 'user', 'system', 'anonymous' ] as const;
export type Scope = typeof Scopes[number];

export function isValidScope( value: any ): value is Scope {
  return Scopes.includes( value );
}
