import { GeneralError } from '@ssp/utils';

export type ContextErrorData = {
  /** The key that was not found. */
  key: string;
};
export type ContextErrorTags = {
  /** The key that was not found. */
  key: string;
  scope: string;
};

export class ContextError<
  Data extends ContextErrorData = ContextErrorData,
  Tags extends ContextErrorTags = ContextErrorTags,
> extends GeneralError<Data & ContextErrorData, Tags & ContextErrorTags> {
}
export class ContextMissing extends ContextError<{
  /** The key that was missing. */
  key: string;
}> {
  static readonly message = 'Value not found in context';
}
export class ContextAssertion extends ContextError {
  static readonly message = 'Wrong context';
}
