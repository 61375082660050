import _ from 'lodash';
import { NotAuthenticated } from '@ssp/utils';
import { BaseExecScope } from './BaseExecScope';
import { ScopeFunc } from './types';

export class ClientExecScope extends BaseExecScope {

  static root: ClientExecScope;

  constructor( data={} ) {
    super( {
      debugging : /\bssp:context\b/u.test( localStorage.debug ),
      ...data,
    } );
  }

  get scope() { return SSP.user ? 'user' : 'anonymous'; }
  get user() {
    if ( SSP.user ) return SSP.user;
    throw new NotAuthenticated();
  }

  debug( ...args ) {
    if ( ! this.debugging ) return;
    console.log( 'CTX -> ', ...args ); // eslint-disable-line no-console
  }

  async runInContext<R=unknown, A extends any[]=any[]>(
    func: ScopeFunc<R, A>,
    ...args: A
  ): Promise<R> {
    if ( ! _.isFunction( func ) ) {
      throw new TypeError( 'runInContext requires function ' );
    }
    return ( async () => func( ...args ) )();
  }

}
