import { timeout } from '@ssp/utils';

import type { Wrapper } from './types';

const timeoutWrapper: Wrapper = ( context, next ) => {
  const to = context.get( 'timeout' );
  return to ? timeout( to, next() ) : next();
};
timeoutWrapper.order = 10000;

export const WRAPPERS: Wrapper[] = [
  timeoutWrapper,
];
